<template>
  <div>
    <div v-if="reservationsData && reservationsData.length > 0" class="mt-2">
      <div class="vs-component vs-con-table vs-table-primary">
        <header class="header-table vs-table--header">
          <header class="vs-navbar nabarx vs-navbar-null vs-navbar-color-transparent" style="background: transparent;">
            <div class="vs-navbar--header">
              <button class="vs-navbar--btn-responsive"><span class="btn-responsive-line line--1"></span><span
                class="btn-responsive-line line--2"></span><span class="btn-responsive-line line--3"></span></button>
              <div><h3 class="vs-navbar--title py-4">
                Opravljeni prevozi ({{reservationsData.length}})
              </h3></div>
            </div>
            <div class="vs-con-items"></div>
          </header>
        </header>


        <div class="con-tablex vs-con-table vs-table--content">
          <div class="vs-con-tbody vs-table--tbody">
            <table class="vs-table vs-table--tbody-table dailyReportsTable">
              <thead class="vs-table--thead">
              <tr>
                <th style="width: 30px">#</th>
<!--                <th style="width: 90px">ura odh.</th>-->
                <th style="width: 250px">potnik
<!--                <th style="width: 250px">prostovoljec</th>-->
<!--                <th style="width: 250px">avto</th>-->
                <th style="width: auto">mesto odhoda</th>
                <th style="width: auto">destinacija</th>
                <th style="width: auto">glavni namen poti</th>
              </tr>
              </thead>
              <tfoot>
              <tr>
                <td colspan="5">
                  <h4 class="mt-2 mb-2"><span class="text-normal">SKUPAJ km po rezervacijah:</span> <b class="text-success text-bold">{{getTotalDistanceByReservations()}} km</b></h4>
                </td>
              </tr>
              </tfoot>
              <tbody>
              <tr class="tr-values vs-table--tr tr-table-state-null"
                  v-for="(reservationItem, reservationItemIndex) in reservationsData.slice().reverse()"
              >
                <td>{{ reservationItemIndex + 1 }}</td>
<!--                <td>-->
<!--                  {{ moment(reservationItem.departure_time).format('HH:mm') }}-->
<!--                </td>-->
                <td>
                  <div class="vs-col vs-xs- vs-sm- vs-lg-"
                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                    <vs-avatar size="60px" :src="reservationItem.customer.gender === 'male' ? sir : madam"
                               :color="reservationItem.customer.gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
                    <div class="ml-2">{{
                        reservationItem.customer.first_name + ' ' + reservationItem.customer.last_name
                      }}
                    </div>
                  </div>
                </td>
<!--                <td>-->
<!--                  <div v-if="reservationItem.volunteer_id != null" class="vs-col vs-xs- vs-sm- vs-lg-"-->
<!--                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">-->
<!--                    <vs-avatar size="60px"-->
<!--                               :src="$globalFunctions.getUserAvatar(reservationItem.volunteer.profile_image)"/>-->
<!--                    <div class="ml-2">{{-->
<!--                        reservationItem.volunteer.first_name + ' ' + reservationItem.volunteer.last_name-->
<!--                      }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-if="reservationItem.volunteer_id == null" class="vs-col vs-xs- vs-sm- vs-lg-"-->
<!--                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">-->
<!--                    <vs-avatar size="60px" :text="'?'"/>-->
<!--                    <div class="ml-2">ni znano</div>-->
<!--                  </div>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div class="vs-col vs-xs- vs-sm- vs-lg-"-->
<!--                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">-->
<!--                    <vs-avatar size="60px" icon="directions_car"-->
<!--                               :style="'background:' + getCarsColor(reservationItem.car)"/>-->
<!--                    <div class="ml-2">{{ getCarsName(reservationItem.car) }}</div>-->
<!--                  </div>-->
<!--                </td>-->
                <td>
                  {{ reservationItem.departure.quick_search.replace(', Slovenija', '') }}
                </td>
                <td>
                  {{ reservationItem.destination.quick_search.replace(', Slovenija', '') }}
                </td>
                <td>

                  <div class="vs-col vs-xs- vs-sm- vs-lg-"
                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                    <vs-avatar size="60px"
                               :icon="$globalFunctions.getReservationPurposeIcon(reservationItem.purpose.id) ? $globalFunctions.getReservationPurposeIcon(reservationItem.purpose.id) : null"
                               :title="reservationItem.purpose.name"/>
                  </div>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>


import Vue from "vue";
import sir from '../../../assets/images/portrait/sir.svg';
import madam from '../../../assets/images/portrait/madam.svg';

export default {
  name: 'reservations-list',

  components: {},
  props: {
    reservationsDate: Number,
    volunteerId: Number
  },
  data() {
    return {
      sir: sir,
      madam: madam,
      reservationsData: null,
    }
  },

  watch: {
    reservationsDate: {
      handler(val, oldVal) {
        console.log("CHANGE!!!!");
        this.reservationsData = null;

        if (val) {
          this.getReservationsForDate();
          this.activeTab = 0;
        }
      }
    }
  },

  computed: {  },

  mounted() {
    const _this = this;

    _this.getReservationsForDate();
  },


  methods: {
    getReservationsForDate() {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'reservationsday/unit/' + _this.$globalFunctions.getCurrentUnit().id + '/' + _this.reservationsDate)
        .then((res) => {

          console.log("reservationsData: ", res.data.data);
          _this.reservationsData = _.filter(res.data.data, {volunteer_id: _this.volunteerId});

          _this.$vs.loading.close();


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    getTotalDistanceByReservations() {
      const _this = this;
      let totalDistance = 0;
      _.each(_this.reservationsData, function (val){
        totalDistance += parseFloat(val.route_distance)
      });

      return totalDistance
    },

    getCarsColor(carData) {
      const _this = this;

      if (carData && _this.$globalFunctions.getCarData(carData.id)) {
        return _this.$globalFunctions.getCarData(carData.id).color
      } else {
        return 'grey'
      }
    },

    getCarsName(carData) {
      const _this = this;

      if (carData && _this.$globalFunctions.getCarData(carData.id)) {
        return _this.$globalFunctions.getCarData(carData.id).name
      } else {
        return 'ni določeno'
      }
    },

  }
}
</script>

