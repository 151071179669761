<template>
  <div id="replaceVolunteerContainer" class="relative">

    <div>

      <vs-list class="mb-5">
        <vs-list-header icon="swap_horizontal_circle"
                        :title="'Zamenjaj prostovoljca za dan ' + moment(dailyReportData.date).format('DD. MM. YYYY') + ' (' + moment(dailyReportData.date).format('dddd') + ')'"></vs-list-header>

        <vs-list-header icon="favorite_border" color="secondary" title="Trenutno izbran prostovoljec"></vs-list-header>
        <vs-list-item v-if="dailyReportData.volunteer"
                      :title="dailyReportData.volunteer.firstName + ' ' + dailyReportData.volunteer.lastName">
          <template slot="avatar">
            <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(dailyReportData.volunteer.profileImage)"/>
          </template>
        </vs-list-item>
        <vs-list-header icon="favorite" color="secondary" title="Nov prostovoljec"></vs-list-header>
        <div v-if="volunteers">

          <div class="mb-5 d-block">
            <div class="w-full">
              <v-select label="first_name" :options="volunteers" class="m-4 mb-5" v-model="newVolunteer">
                <template v-slot:option="option">
                  <vs-list-item
                                :title="option.first_name + ' ' + option.last_name">
                    <template slot="avatar">
                      <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(option.profile_image)" class="mr-5"/>
                    </template>
                  </vs-list-item>
                </template>

                <template v-slot:selected-option="option">
                  <vs-list-item
                    class="d-block"
                    :title="option.first_name + ' ' + option.last_name">
                    <template slot="avatar">
                      <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(option.profile_image)" class="mr-5"/>
                    </template>
                  </vs-list-item>
                </template>

                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                  </template>
                  <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                </template>

              </v-select>
            </div>
          </div>

        </div>
      </vs-list>

    </div>

    <div class="clearfix clear"></div>
    <vs-row
      vs-align="center"
      vs-type="flex" vs-justify="space-around" vs-w="12" class="mt-5">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <vs-button color="primary" icon="save" @click="saveVolunteerReplacement()">Shrani</vs-button>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <vs-button color="danger" icon="cancel" @click="() => { $emit('cancel') }">Prekliči</vs-button>
      </vs-col>
    </vs-row>

  </div>
</template>

<script>
  import Vue from 'vue';
  import vSelect from 'vue-select'

  export default {
    name: 'replace-volunteer',

    components: {
      'v-select': vSelect,
    },
    props: {
      dailyReportData: Object
    },
    data() {
      return {
        volunteers: null,
        newVolunteer: null
      }
    },

    computed: {},

    mounted() {
      const _this = this;

      console.log("dailyReportData", this.dailyReportData);

      _this.getVolunteers();
    },


    methods: {
      getVolunteers() {
        const _this = this;

        _this.$vs.loading();

        Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/unit/' + _this.$globalFunctions.getCurrentUnit().id)
          .then((res) => {

            _this.volunteers = _.filter(res.data.data, (volunteer) => volunteer.unit_id === _this.$globalFunctions.getCurrentUnit().id && volunteer.active === 1);
            _this.$vs.loading.close();
            console.log("----> _this.volunteers ", _this.volunteers);

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },

      async saveVolunteerReplacement() {
        const _this = this;


        if (!_this.newVolunteer) {
          _this.$vs.notify({
            title: 'Napaka!',
            text: 'Nov prostovoljec ni določen!',
            color: 'danger',
            position: 'top-center'
          });
          return;
        }

        _this.$vs.loading();

        let newDailyReportData = {
          oldVolunteerId: _this.dailyReportData.volunteer.id,
          newVolunteerId: _this.newVolunteer.id
        }

        await Vue.prototype.$http.put(`${Vue.prototype.$config.api.sopotnikiVPS}daily-reports/${_this.dailyReportData.id}/change-volunteer`, newDailyReportData)
          .then((res) => {

            _this.$vs.loading.close();
            _this.$emit('cancel');
            _this.$emit('reloadDailyReports');

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });


      }


    }
  }
</script>

